import { useState } from "react";
import { ethers } from "ethers";
import abi from '../contract_abi.json';
import { Button, TextField, Box, Typography } from '@mui/material';
import ErrorModal from "./ErrorModal";
import EstateInfoModal from "./EstateInfoModal";

const ReadEstate = () => {

  const contract_address = '0x75e35b565B172136d12b2F49DD7E8aa8fF75eD12'

  const [estate, setEstate] = useState({
    amountToHold: '',
    daysToHold: '',
    childAddress: '',
    parentAddress: '',
    tokenId: '',
    contractAddress: ''
  })


  const [inputtedEstateId, setInputtedEstateId] = useState('')
  const [showEstateInfo, setShowEstateInfo] = useState(false)
  const [showError, setShowError] = useState(false)

  const estateInfo = <EstateInfoModal estate={estate} show={showEstateInfo} setShow={setShowEstateInfo} />
  const error = <ErrorModal errorMessage={"Please enter a valid number"} showError={showError} setShowError={setShowError} />

  const getEstate = async () => {
    console.log("getEstate called")
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contract_address, abi, signer);
    contract.return_estate_data(inputtedEstateId).then((data) => {
      console.log(data)
      setEstate({
        amountToHold: data.wei_stored ? data.wei_stored.toString() : '',
        daysToHold:  data.seconds_to_hold ? data.seconds_to_hold.toString() : '',
        childAddress: data.child_address ? data.child_address.toString() : '',
        parentAddress: data.parent_address ? data.parent_address.toString() : '',
        tokenId: data.nft_token_id ? data.nft_token_id.toString() : '',
        contractAddress: data.nft_contract_address ? data.nft_contract_address.toString() : ''
      })
    });
    setInputtedEstateId(``)
  }

  return (
    <Box display={'flex'} justifyContent={'center'} mt={'60px'}>
      <Box display={"flex"} flexDirection={'column'} width={'50%'}>
      <Typography variant="h3">View Estate</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          label="Estate ID"
          value={inputtedEstateId}
          error={(isNaN(inputtedEstateId) && inputtedEstateId !== '') ? true : false}
          helperText={(isNaN(inputtedEstateId) && inputtedEstateId !== '') ? "Please enter a valid number" : null}
          onChange={(event) => setInputtedEstateId(event.target.value)} />
        <Button
          variant="contained"
          onClick={() => {
            getEstate()
            setShowEstateInfo(!showEstateInfo)
          }}
        >View Estate</Button>

        {showEstateInfo ? estateInfo : <div></div>}

        {showError ? error : <></>}
        
      </Box>
    </Box>
  )
}

export default ReadEstate;