import React from "react";
import Faq from "react-faq-component";
import { Box } from "@mui/system";

const data = {
    title: "FAQ",
    rows: [
        {
            title: "What is Estate Manager?",
            content: `Estate Manager is a decentralized application (dApp) that allows users to create, view, and collect digital assets.`,
        },
        {
            title: "Why should I use Estate Manager?",
            content:
                "Nunc maximus, magna at ultricies elementum, risus turpis vulputate quam, vitae convallis ex tortor sed dolor.",
        },
        {
            title: "Where is the source code for Estate Manager?",
            content: `The source code for Estate Manager can be found here.`,
        },
        {
            title: "What is the package version",
            content: <p>current version is 1.2.1</p>,
        },
        {
            title: "What is the contract address for Estate Manager?",
            content: <p>0xAB8D6F82c384A0c49fd1aB029c4C297Da14BB75B</p>,
        }
    ],
};

const styles = {
    // bgColor: 'white',
    titleTextColor: "grey",
    rowTitleColor: "grey",
    // rowContentColor: 'grey',
    // arrowColor: "red",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};

export default function FAQ() {

    return (
        <Box width={'50%'} ml={'25%'}>
          <Faq
              data={data}
              styles={styles}
              config={config}
          />
        </Box>
    );
}