import { Box, Typography, useMediaQuery } from "@mui/material";
import Computer from '../assets/computer.svg'
import Bitcoin from '../assets/BTC.png'
import Ethereum from '../assets/ETH.png'
import Chain from '../assets/LINK.png'
import Uni from '../assets/UNI.png'
//import Badge from '../assets/BADGE.png'

const LandingPage = () => {

  const isMobile = useMediaQuery('(max-width: 1000px)');

  return (
    <div>
      <Box mt={isMobile ? '10px' : '50px'} display={'flex'} justifyContent={"space-around"}>
        <Box width={'40%'} ml={ isMobile ? '75px' : '100px'} mt={isMobile ? '75px' : '150px'} maxWidth={'400px'}>
          <Typography variant={isMobile ? "h3" : "h2" }>Protect Your Crypto Estate</Typography>
          <Typography>
            With Estate Manager, you can protect your valuable crypto assets from being lost in the case of lost wallets or sudden end of life events.
          </Typography>
        </Box>
        {/* Put Crypto Image Here */}
        <img
          src={Computer}
          alt="Computer"
          height={isMobile ? '300px' : '500px'}
          width={'500px'}
          />
      </Box>

      {/* Assets Supported */}
      <Box>
        <Typography variant="h5" ml={'25%'}>Supported Assets</Typography>
        <Box display={'flex'} ml={'20%'} justifyContent={'center'} alignItems={'center'} 
          width={'60%'} bgcolor={'lightgray'}
          sx={{ 
            opacity: '75%',
            borderRadius: '10px', 
          }}>
          <img src={Bitcoin} alt="Bitcoin Logo" height={isMobile ? '120px' : '150px' }/>
          <img src={Ethereum} alt="Ethereum Logo" height={isMobile ? '120px' : '150px'}/>
          <img src={Chain} alt="Chainlink Logo" height={isMobile ? '90px' : '120px'}/>
          <img src={Uni} alt="Uniswap Logo" height={isMobile ? '110px' : '140px'}/>
        </Box>
      </Box>

      {/* About Estate Manager */}
      <Box mt={'50px'}>
        <Typography variant="h3" ml={'25%'}>About:</Typography>
        <Typography width={'50%'} ml={'20%'}>
          Estate Manager is a smart contract written on the Polygon Network. It allows users to make their crypto assets inheritable to another account in the case of lost keys or an end of life event. Estate Manager allows you to store ERC-20 Tokens and NFTs, protecting your valuable assets. The source code for Estate Manager can be found here and the deployed smart contract can be found here.
        </Typography>
      </Box>
    </div>
  )
}

export default LandingPage;