import NavBar from './components/NavBar';
import LandingPage from './components/LandingPage';
import FAQ from './components/FAQ';
import CreateEstate from './components/CreateEstate';
import ReadEstate from './components/ReadEstate';
import CollectEstate from './components/CollectEstate';
import Footer from './components/Footer';

function App() {
 
  return (
    <div style={{ width: '100%', overflowX: 'hidden' }}>
      <NavBar/>
      <LandingPage/>
      <FAQ/>
      <CreateEstate/>
      <ReadEstate/>
      <CollectEstate/>
      <Footer/>
    </div>
  );
}

export default App;
