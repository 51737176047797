import React from "react";
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const EstateInfoModal = ({ estate, show, setShow }) => {

  let modal = <Box bgcolor={'lightgray'} marginTop={'40px'} padding={'20px'} borderRadius={2} position={'relative'} paddingBottom={'50px'}>
    <Typography variant="h5">Estate Information</Typography>
    <Typography>Time Left: {estate.daysToHold}</Typography>
    <Typography>Polygon: {estate.amountToHold}</Typography>
    <Typography>Inhereit Address: {estate.childAddress}</Typography>
    <Typography>Parent Address: {estate.parentAddress}</Typography>
    <Typography>ERC Tokens: {estate.amountToHold}</Typography>
    <Typography>NFT ID: {estate.tokenId}</Typography>
    <IconButton
      sx = {{
        position: 'absolute',
        right: 0,
        marginRight: '10px'
      }}
      onClick={() => setShow(!show)}>
      <CloseIcon />
    </IconButton>
  </Box>

  return (
    <div>
      {show ? modal : null}
    </div>
  );
}

export default EstateInfoModal;