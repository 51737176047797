import { useEffect, useState } from "react";
import { ethers } from "ethers";
import abi from '../contract_abi.json'
import { Button, TextField, Box, Select, MenuItem, Typography, InputLabel, FormControl } from '@mui/material';

const CreateEstate = () => {

  const contract_address = '0x75e35b565B172136d12b2F49DD7E8aa8fF75eD12'
  const one_eth_in_wei = 1000000000000000000
  
  const [newEstate, setNewEstate] = useState({
    amountToHold: '',
    daysToHold: '',
    childAddress: '',
    parentAddress: '',
    tokenId: '',
    contractAddress: ''
  })

  const [typeOfEstate, setTypeOfEstate] = useState('')
  const [disableButton, setDisableButton] = useState(false)

  useEffect(() => {
  if (isNaN(newEstate.amountToHold) || isNaN(newEstate.daysToHold) || isNaN(newEstate.tokenId)) {
    setDisableButton(true)
  } else {
    setDisableButton(false)
  }
  }, [newEstate])

  const isErc20 = (tokenName) => {
    return tokenName === 'Chainlink' || tokenName === 'Doge' || tokenName === 'USDC' || tokenName === 'USDT' || tokenName === 'DAI'
  }


    const nftIdInput = <TextField
      error={isNaN(newEstate.tokenId)}
      helperText={isNaN(newEstate.tokenId) ? "Please enter a valid number" : null}
      variant="outlined"
      label="Token ID"
      value={newEstate.tokenId}
      margin="normal"
      onChange={(event) => setNewEstate({...newEstate, tokenId: event.target.value})} />

  const contractAddressInput = <TextField
    error={(newEstate.contractAddress != null && newEstate.contractAddress.length !== 42) && newEstate.contractAddress !== ''}
    helperText={((newEstate.contractAddress != null && newEstate.contractAddress.length !== 42) && newEstate.contractAddress !== '') ? "Please enter a valid address" : null}
    variant="outlined"
    label="Contract Address"
    value={newEstate.contractAddress}
    margin="normal"
    onChange={(event) => setNewEstate({...newEstate, contractAddress: event.target.value})} />

  const CreateRegularEstate = async () => {
    console.log("Create Regular Estate called")
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contract_address, abi, signer);
    console.log(contract)
    const wei_to_hold = String(one_eth_in_wei * newEstate.amountToHold)
    const overrides = {value: wei_to_hold}
    const seconds_to_hold = newEstate.daysToHold
    contract.make_regular_estate(seconds_to_hold, newEstate.childAddress, overrides);
    setNewEstate({amountToHold: '', daysToHold: '', childAddress: '', parentAddress: '', tokenId: '', contractAddress: '' })
  }

  const CreateErc20Estate = async () => {
    console.log("Create Estate called")
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contract_address, abi, signer);
    console.log(contract)
    const seconds_to_hold = newEstate.daysToHold
    contract.make_erc20_estate(seconds_to_hold, newEstate.childAddress, newEstate.contractAddress, newEstate.amountToHold);
    setNewEstate({amountToHold: '', daysToHold: '', childAddress: '', parentAddress: '', tokenId: '', contractAddress: '' })
  }

  const CreateErc721Estate = async () => {
    console.log("Create Estate called")
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contract_address, abi, signer);
    console.log(contract)
    const wei_to_estate = String(one_eth_in_wei * newEstate.ethToEstate)
    const overrides = {value: wei_to_estate}
    const seconds_to_hold = newEstate.daysToHold
    contract.make_estate(seconds_to_hold, newEstate.childAddress, newEstate.contractAddress, newEstate.amountToHold, newEstate.tokenId, overrides);
    setNewEstate({amountToHold: '', daysToHold: '', childAddress: '', parentAddress: '', tokenId: '', contractAddress: '' })
  }

  return (
    <Box display={'flex'} justifyContent={'center'} mt={'60px'}>
      <Box display={"flex"} flexDirection={'column'} width={'50%'}>
        <Typography variant="h3" marginBottom={'25px'}>Create Estate</Typography>
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Type of Estate</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={typeOfEstate}
          label="Type of Estate"
          onChange={(event) => setTypeOfEstate(event.target.value)}
        >
          <MenuItem value={'NFT'}>NFT</MenuItem>
          <MenuItem value={'Chainlink'}>Chainlink</MenuItem>
          <MenuItem value={'Doge'}>Doge</MenuItem>
          <MenuItem value={'Polygon'}>Polygon</MenuItem>
        </Select>
      </FormControl>
        <TextField
          error={isNaN(newEstate.amountToHold)}
          helperText={isNaN(newEstate.amountToHold) ? "Please enter a valid number" : null}
          variant="outlined"
          label="Amount To Hold"
          value={newEstate.amountToHold}
          onChange={(event) => setNewEstate({...newEstate, amountToHold: event.target.value})}
          margin="normal"
          width={'25%'} />
        <TextField
          my={'25px'}
          error={isNaN(newEstate.daysToHold)}
          helperText={isNaN(newEstate.daysToHold) ? "Please enter a valid number" : null}
          variant="outlined"
          label="Days to Hold"
          value={newEstate.daysToHold}
          margin="normal"
          onChange={(event) => setNewEstate({...newEstate, daysToHold: event.target.value})} />
        <TextField
          error={(newEstate.childAddress != null && newEstate.childAddress.length !== 42) && newEstate.childAddress !== ''}
          helperText={((newEstate.childAddress != null && newEstate.childAddress.length !== 42) && newEstate.childAddress !== '') ? "Please enter a valid address" : null}
          variant="outlined"
          label="Inherit Address"
          value={newEstate.childAddress}
          margin="normal"
          onChange={(event) => setNewEstate({...newEstate, childAddress: event.target.value})} />

        { (isErc20(typeOfEstate) || typeOfEstate === 'NFT') ? contractAddressInput : <></>}
        { typeOfEstate === 'NFT' ? nftIdInput : <></> }

        <Button
          variant="contained"
          disabled={disableButton}
          onClick={() => {
            if (typeOfEstate === 'NFT') {
              CreateErc721Estate()
            } else if (isErc20(typeOfEstate)) {
              CreateErc20Estate()
            } else {
              CreateRegularEstate()
              //createHold()
            }
          }}>Create Estate</Button>
        </Box>
    </Box>
  )
}

export default CreateEstate