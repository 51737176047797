import React from 'react';
import { Typography, Box, Divider } from '@mui/material';

const Footer = () => {
  return (
    <Box height={'100px'} mt={'50px'} mx={'50px'}>
      <Divider/>
      <Typography marginTop={'10px'} marginLeft={'20%'}>Created by <a href='https://jackantico.io/'>Jack Antico</a></Typography>
    </Box>
  );
};

export default Footer;
