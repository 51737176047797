import { useState } from "react";
import { ethers } from "ethers";
import abi from '../contract_abi.json';
import { Button, TextField, Box, Typography, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import ErrorModal from "./ErrorModal";
import EstateInfoModal from "./EstateInfoModal";

const CollectEstate = () => {

  const contract_address = '0x75e35b565B172136d12b2F49DD7E8aa8fF75eD12'

  const [estate, setEstate] = useState({
    amountToestate: '',
    daysToHold: '',
    inhereitAddress: '',
    parentAddress: '',
    tokenId: '',
    contractAddress: ''
  })


  const [typeOfEstate, setTypeOfEstate] = useState('')
  const [parentEstateId, setParentEstateId] = useState('')
  const [childEstateId, setChildEstateId] = useState('')
  const [showParentEstateInfo, setShowParentEstateInfo] = useState(false)
  const [showChildEstateInfo, setShowChildEstateInfo] = useState(false)
  const [showError, setShowError] = useState(false)

  const isErc20 = (tokenName) => {
    return tokenName === 'Chainlink' || tokenName === 'Doge' || tokenName === 'USDC' || tokenName === 'USDT' || tokenName === 'DAI'
  }

  const collectRegularEstate = (id, isChild) => {
    console.log("collect regular estate called")
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    //const overrideOptions = {gasLimit: 6000000}
    const contract = new ethers.Contract(contract_address, abi, signer);
    contract.collect_regular_estate(id, isChild).then((data) => {
      console.log(data)
    })
  }

  const collectErc20Estate = (id, isChild) => {
    console.log("collect ERC 20 called")
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contract_address, abi, signer);
    contract.collect_erc20_estate(id, isChild);
  }

  const collectErc721Estate = (id, isChild) => {
    console.log("collect ERC 721 called")
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contract_address, abi, signer);
    contract.collect_erc721_estate(id, isChild);
  }

  const estateInfoChild = <EstateInfoModal estate={estate} show={showChildEstateInfo} setShow={setShowChildEstateInfo} />

  const estateInfoParent = <EstateInfoModal estate={estate} show={showParentEstateInfo} setShow={setShowParentEstateInfo} />

  const error = <ErrorModal errorMessage={"Please enter a valid number"} showError={showError} setShowError={setShowError} />

  const handleChange = (event) => {
    setTypeOfEstate(event.target.value);
  };

  return (
    <Box display={'flex'} justifyContent={'center'} mt={'60px'}>
      <Box display={"flex"} flexDirection={'column'} width={'50%'}>
        <Typography variant="h3">Collect Estate As Child</Typography>
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Type of Estate</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={typeOfEstate}
          label="Type of Estate"
          onChange={handleChange}
        >
          <MenuItem value={'NFT'}>NFT</MenuItem>
          <MenuItem value={'Chainlink'}>Chainlink</MenuItem>
          <MenuItem value={'Doge'}>Doge</MenuItem>
          <MenuItem value={'Polygon'}>Polygon</MenuItem>
        </Select>
        </FormControl>
        <TextField
          error={isNaN(childEstateId)}
          helperText={isNaN(childEstateId) ? "Please enter a valid number" : null}
          variant="outlined"
          margin="normal"
          label="Estate ID"
          value={childEstateId}
          onChange={(event) => setChildEstateId(event.target.value)} />
        <Button
          variant="contained"
          onClick={() => {
            if (typeOfEstate === 'NFT') {
              collectErc721Estate(childEstateId, true)
            } else if (typeOfEstate === 'Chainlink') {
              collectErc20Estate(childEstateId, true)
            } else {
              collectRegularEstate(childEstateId, true)
            }
            setShowChildEstateInfo(!showChildEstateInfo)
          }}
        >Collect Estate</Button>

        {showChildEstateInfo ? estateInfoChild : null}

        {showError ? error : null}
        
        <Typography variant="h3" mt={'60px'}>Collect Estate as Parent</Typography>
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Type of Estate</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={typeOfEstate}
          label="Type of Estate"
          onChange={handleChange}
        >
          <MenuItem value={'NFT'}>NFT</MenuItem>
          <MenuItem value={'Chainlink'}>Chainlink</MenuItem>
          <MenuItem value={'Doge'}>Doge</MenuItem>
          <MenuItem value={'Polygon'}>Polygon</MenuItem>
        </Select>
        </FormControl>
        <TextField
          error={isNaN(parentEstateId)}
          helperText={isNaN(parentEstateId) ? "Please enter a valid number" : null}
          variant="outlined"
          margin="normal"
          label="estate ID"
          value={parentEstateId}
          onChange={(event) => setParentEstateId(event.target.value)} />
        <Button
          variant="contained"
          onClick={() => {
            if (typeOfEstate === 'NFT') {
              collectErc721Estate(parentEstateId, false)
            } else if (isErc20(typeOfEstate)) {
              collectErc20Estate(parentEstateId, false)
            } else {
              collectRegularEstate(parentEstateId, false)
            }
            setShowParentEstateInfo(!showParentEstateInfo)
          }}
          >Collect Estate</Button>

        {showParentEstateInfo ? estateInfoParent : null}
      </Box>
    </Box>
  )
}

export default CollectEstate;