import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ErrorModal = ({ errorMessage, showError, setShowError }) => {

  let modal = <Box bgcolor={'red'} marginTop={'40px'} padding={'20px'} borderRadius={2} paddingBottom={'50px'} position={'relative'} >
    <Typography variant="h5">Error</Typography>
    <Typography>{errorMessage}</Typography>
    <IconButton
      sx = {{
        position: 'absolute',
        right: 0,
        marginRight: '10px'
      }}
      onClick={() => setShowError(!showError)}>
      <CloseIcon />
    </IconButton>
  </Box>

  return (
    <div>
      {showError ? modal : null}
    </div>
  );
}

export default ErrorModal;