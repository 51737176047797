import { Box, Typography } from "@mui/material";
import MaticLogo from '../assets/polygon-matic-logo.svg';
import GithubLogo from '../assets/github-mark.svg'
import { useMediaQuery } from "@mui/material";

const NavBar = () => {

  const isMobile = useMediaQuery('(max-width: 1000px)');

  return (
    <Box  width='100%' display={'flex'} justifyContent={"space-between"} 
      alignItems={'center'}>

        { isMobile ? (
          // Mobile Nav
          <Box display={'flex'}>
            <Typography variant="h4" mx={'50px'}>Estate Manager</Typography>
            <Typography
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                  color: 'blue'
                }
              }}
              onClick={(() => window.scrollTo(0, 1000))}
              variant="h5" m={'5px 20px'}>Create</Typography>
            <Typography 
              variant="h5" m={'5px 20px'}
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                  color: 'blue'
                }
              }}
              onClick={(() => window.scrollTo(0, 2000))}>Collect</Typography>
          </Box>
        ) : (
          // Desktop Nav
          <Box display={'flex'}>
            <Typography variant="h4" mx={'50px'}>Estate Manager</Typography>
            <Typography
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                  color: 'blue'
                }
              }}
              onClick={(() => window.scrollTo(0, 1000))}
              variant="h5" m={'5px 20px'}>Create</Typography>
            <Typography 
              variant="h5" m={'5px 20px'}
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                  color: 'blue'
                }
              }}
              onClick={(() => window.scrollTo(0, 2000))}>Collect</Typography>
          </Box>
        )}
      

      {/* LOGOS */}
      <Box mr={'50px'} display={"flex"}>
        <Box mt={'10px'} mr={"50px"}>
          <img
            src={GithubLogo}
            alt="Github logo"
            height={'50px'}
            width={'50px'}
            onClick={(() => window.location = "https://polygonscan.com/address/0xab8d6f82c384a0c49fd1ab029c4c297da14bb75b")}
            style={{ cursor: 'pointer' }}
            />
        </Box>
        <Box mt={'14px'} height={'50px'} width={'50px'}>
          <img 
            src={MaticLogo} 
            alt="Matic Logo"
            height={'50px'}
            width={'50px'}
            onClick={(() => window.location = "https://polygonscan.com/address/0xab8d6f82c384a0c49fd1ab029c4c297da14bb75b")}
            style={{ cursor: 'pointer' }}
            />
        </Box>
      </Box>
    </Box>
  )
}

export default NavBar;